<template>
    <div v-if="store.lists.length" class="p-[16px_20px] gap-[8px] border-b-[1px] border-b-[#E2E4E9] flex flex-col">
        <button class="p-[8px] border-[1px] border-[#E2E4E9] rounded-[10px]"
            v-for="list in store.lists"
            :key="list"
            @click="toggle(list)"
            :class="{'!border-[#B692F6] bg-[#F9F5FF]': isProfileInList(list) }"
        >
            {{ list.name }}
        </button>
    </div>
    <div v-else class="p-[16px_20px] gap-[8px] border-b-[1px] border-b-[#E2E4E9] flex flex-col">
        <span class="block bg-[#E5E7EB] h-[38px] w-full rounded-[8px] animate-pulse"></span>
        <span class="block bg-[#E5E7EB] h-[38px] w-full rounded-[8px] animate-pulse"></span>
    </div>
    <div class="p-[16px_20px] flex gap-[12px]">
        <button class="button-secondary w-full" @click="onSave()">Save</button>
        <button class="button-primary w-full" @click="onCreate()">Create New List</button>
    </div>
</template>
<script>
import useListStore from '@/stores/List';

export default {
    setup: () => ({ store: useListStore() }),
    props: {
        interface: {
            type: Object,
            required: true
        },
        params: {
            type: Object,
            default: null
        }
    },
    async mounted() {
        this.interface.modal.setHeader('Add Profile', 'list');
        this.setParams(this.params);
        this.onFetchLists();
    },
    methods: {
        isProfileInList(list) {
            return list.items.includes(this.store.currentProfile.id);
        },
        setParams(params) {
            if (params) {
                this.store.currentProfile.id = Number(params.id);
                this.store.currentProfile.name = params.name;
            }
        },
        async onFetchLists() {
            await this.store.fetchLists();

            if (!this.store.lists.length) {
                this.interface.modal.setView('ModalListCreate');
            }
        },
        onSave() {
            this.interface.modal.close();
            this.interface.notify(`"${this.store.currentProfile.name}" was saved!`);
        },
        onCreate() {
            this.interface.modal.setView('ModalListCreate');
        },
        async onAdd(list) {
            await this.store.addProfile(list.id, {
                profile_id: this.store.currentProfile.id
            });
        },
        async onRemove(list) {
            await this.store.removeProfile(list.id, {
                profile_id: this.store.currentProfile.id
            });
        },
        async toggle(list) {
            await (this.isProfileInList(list) ? this.onRemove(list) : this.onAdd(list));
        }
    }
}
</script>